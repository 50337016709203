import React from 'react';
import { Row, Col } from 'react-bootstrap';
import WebpImage from '../webp-image';
import './style.css';

const seenImages = [
  { image: 'seenBump', alt: 'As seen in The Bump' },
  { image: 'seenScarymommy', alt: 'As seen in Scary Mommy' },
  { image: 'seenPregnancyandnewborn', alt: 'seen in Pregnancy and Newborn' },
  { image: 'seenInStyle', alt: 'As seen in InStyle' },
  { image: 'seenWhattoexpect', alt: 'As seen in What To Expect' },
  { image: 'seenBuzzfeed', alt: 'As seen in BuzzFeed' },
  {
    image: 'seenProjectNursery',
    alt: 'As seen in Project Nursery',
    hideFromMobile: true,
  },
];

const breastPumpImages = [
  { image: 'seenAmeda', alt: 'Ameda Breast Pumps' },
  { image: 'seenArdo', alt: 'Ardo Breast Pumps' },
  { image: 'seenElvie', alt: 'Elvie Breast Pumps' },
  { image: 'seenEvenflo', alt: 'EvenFlo Breast Pumps' },
  { image: 'seenMedela', alt: 'Medela Breast Pumps' },
  { image: 'seenLansinoh', alt: 'Lansinoh Breast Pumps' },
  { image: 'seenMotif', alt: 'Motif Breast Pumps' },
  { image: 'seenSpectra', alt: 'Spectra Breast Pumps' },
];

const AsSeenIn = ({ isBreastPump }) => {
  const logos = isBreastPump ? breastPumpImages : seenImages;

  return (
    <Row>
      <Col className='asSeenIn'>
        {!isBreastPump && <h2>As Seen In</h2>}
        {logos.map((logo) => (
          <div
            key={logo.image}
            className={
              !logo.hideFromMobile ? 'asSeenInContainer' : 'hideFromMobile'
            }
          >
            <WebpImage
              imageName={logo.image}
              alt={logo.alt}
              width='43'
              height='60'
            />
          </div>
        ))}
      </Col>
    </Row>
  );
};

export default React.memo(AsSeenIn);
