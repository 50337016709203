import React from 'react';
import { Link } from 'react-router-dom';
import './style.css';
import { Row, Col, Button } from 'react-bootstrap';
import ExternalImage from '../product/ExternalImage';
import MoneyUtils from '../../services/MoneyUtils';

function ProductItem(props) {
  const disableClick = (e) => e.preventDefault();

  const getProductImage = (product, variation) => {
    const { media } = product;
    if (!media || !media.length) {
      return 'https://s3.amazonaws.com/bitsy-product-images/default-product-image.png';
    }

    if (!variation) {
      return media[0].url;
    }

    const variationIndex = media
      .map((mediaItem) => mediaItem.variation)
      .indexOf(variation);

    if (variationIndex === -1) {
      return 'https://s3.amazonaws.com/bitsy-product-images/default-product-image.png';
    }

    return media[variationIndex].url;
  };

  // Product info
  const isSubscription = props.product.subscriptionPlan?.months === 'INFINITY';
  const productQuantity = props.variation
    ? props.variationQuantity
    : props.product.totalQuantity;
  const productImage = getProductImage(props.product, props.variation);
  const w = window.innerWidth;
  let productName = props.variation
    ? `${props.product.name} - ${props.variation}`
    : props.product.name;
  if (w <= 400 && productName.length > 33) {
    productName = `${props.product.name.slice(0, 30)}...`;
  }

  let actualCost;
  let originalCost;
  if (props.product.subscriptionPlan) {
    actualCost = props.product.subscriptionPlan.costWithCoupon;
    ({ originalCost } = props.product.subscriptionPlan);
  } else {
    ({ actualCost } = props.product);
    ({ originalCost } = props.product);
  }

  if (
    props.postPurchasePromo &&
    props.product.ownPromoData &&
    (props.product.ownPromoData.isPostPurchasePromo ||
      props.product.ownPromoData.isVitaminPromo ||
      props.product.ownPromoData.isPreCheckoutPromo ||
      props.product.ownPromoData.isSubPromo)
  ) {
    actualCost = props.product.ownPromoData.cost;
    ({ originalCost } = props.product);
  }

  if (props.amountToCharge) {
    actualCost = props.amountToCharge;
  }

  const determineColumnWidth = (size) => {
    if (size === 'xs' || size === 'sm') {
      if (props.threeColumns) return { span: 10, offset: 1 };
      if (props.isPostPurchasePromoVitamin || props.isRecurringAddOn) return 12;
      if (props.horizontalLayout) return 6;
      return 6;
    }

    if (size === 'md') {
      if (props.threeColumns) return { span: 3, offset: 0 };
      if (props.isPostPurchasePromoVitamin) return 12;
      if (props.horizontalLayout) return 6;
      if (props.postPurchasePromo) return 4;
      return 3;
    }

    if (size === 'lg') {
      if (props.threeColumns) return { span: 3, offset: 0 };
      if (props.isPostPurchasePromoVitamin) return 12;
      if (props.horizontalLayout) return 6;
      if (props.postPurchasePromo) return 4;
      return 3;
    }

    return 4;
  };

  return (
    <Col
      key={`${props.product._id}-${props.index}`}
      xs={determineColumnWidth('xs')}
      sm={determineColumnWidth('sm')}
      md={determineColumnWidth('md')}
      lg={determineColumnWidth('lg')}
      className={`productItem ${props.horizontalLayout ? 'horizontal' : ''}`}
      id={props.id || 'productItemCol'}
    >
      <Row className='productItemRow'>
        <Col
          xs={12}
          sm={12}
          md={props.horizontalLayout ? 6 : 12}
          lg={props.horizontalLayout ? 6 : 12}
          className={
            !props.productRecommendations
              ? 'productImage'
              : 'productRecommendationsImage'
          }
        >
          <Link
            to={{
              pathname: `/products/${props.product.urlName?.toLowerCase()}`,
              state: {
                positionForViewEvent: props.positionForViewEvent,
                isPromoForViewEvent: props.isPromoForViewEvent,
              },
            }}
            className={
              !props.disableProductClick ? 'productLink' : 'disabled-link'
            }
            onClick={props.disableProductClick && disableClick}
          >
            <ExternalImage
              src={productImage}
              width={550}
              height={550}
              sizes='(max-width: 414px) 50vw, 25vw'
              alt={props.product.name}
              title={`${props.product.name} Photo`}
            />
          </Link>
        </Col>
        <Col
          className={
            props.horizontalLayout
              ? 'shopProductInfo horizontal'
              : 'shopProductInfo'
          }
          xs={12}
          sm={12}
          md={props.horizontalLayout ? { span: 5, offset: 1 } : 12}
          lg={props.horizontalLayout ? { span: 5, offset: 1 } : 12}
        >
          <Link
            to={{
              pathname: `/products/${props.product.urlName?.toLowerCase()}`,
              state: {
                positionForViewEvent: props.positionForViewEvent,
                isPromoForViewEvent: props.isPromoForViewEvent,
              },
            }}
            className={
              !props.disableProductClick ? 'productLink' : 'disabled-link'
            }
            onClick={props.disableProductClick && disableClick}
          >
            <div className='productTitle'>{productName}</div>
            <div className='productBrand'>{props.product.brand}</div>
          </Link>
          {props.isPostPurchasePromoVitamin ? (
            <div className='productDescription'>
              {props.product.description}
            </div>
          ) : null}
          <div
            id='starRating'
            className='yotpo bottomLine'
            data-yotpo-product-id={props.product.sku}
          />
          {props.vipDiscount ? (
            <div className='vip-discount'>
              VIP Discount ({props.vipDiscount}% Off)
            </div>
          ) : null}
          {props.viewDetails && props.product.description ? (
            <Button
              variant='link'
              className='viewDetailsButton'
              id={props.product._id}
              onClick={props.viewProductDetails}
              onKeyDown={props.viewProductDetails}
            >
              View Details
            </Button>
          ) : null}
          {!props.isReplacementItem ? (
            <Row noGutters className='priceWrapper'>
              {props.product.discounted === true ? (
                <div>
                  <div className='center-align'>
                    <div>
                      <b>{isSubscription ? 'Monthly' : 'Retail'} Price: </b>$
                      {MoneyUtils.formatAsMoney(originalCost)}
                    </div>
                  </div>
                </div>
              ) : null}
              <div className='productPrice'>
                {props.product.discounted === true && (
                  <b>{isSubscription ? '1st Box' : 'Our'} Price: </b>
                )}
                <span className='productPriceHighlight'>
                  ${MoneyUtils.formatAsMoney(actualCost)}
                  {props.isPostPurchasePromoVitamin || props.isRecurringAddOn
                    ? '/month'
                    : null}
                </span>
              </div>
            </Row>
          ) : null}
          {props.detailsAction ? (
            <div
              onClick={props.detailsAction}
              role='button'
              tabIndex={props.index}
              onKeyUp={props.detailsAction}
              className='details'
            >
              Details
            </div>
          ) : null}
          {productQuantity < 10 && productQuantity > 0 ? (
            <Row>
              <Col className='productQuantityLeft'>
                Only {productQuantity} left in stock!
              </Col>
            </Row>
          ) : null}
          {!props.isReplacementItem ? (
            <div className='productAddToCart'>
              <Button
                disabled={props.isButtonDisabled}
                onClick={props.buttonAction}
                variant={props.buttonVariant ? props.buttonVariant : 'primary'}
                className={
                  props.productRecommendations
                    ? 'productRecommendationsAddToCart'
                    : 'addToCartShop'
                }
                aria-label={`Add ${productName} to cart`}
              >
                {props.buttonText}
              </Button>
            </div>
          ) : null}
        </Col>
      </Row>
    </Col>
  );
}

export default React.memo(ProductItem);
